import { Caches } from "../caches/caches";

export function getScadenzaPiano(sedeEnpoint) {
  const dataInizioDate = new Date(
    Caches.baseInfoData[sedeEnpoint].plan.dataPay
  );

  dataInizioDate.setDate(
    dataInizioDate.getDate() + Caches.baseInfoData[sedeEnpoint].plan.durata
  );
  const oggiPiu30 = new Date();
  oggiPiu30.setDate(oggiPiu30.getDate() + 30);
  const oggi = new Date();
  const differenzaMs = dataInizioDate - oggi;

  const giorniMancanti = Math.ceil(differenzaMs / (1000 * 60 * 60 * 24));
  return giorniMancanti;
}
