import { TextInput } from '@tremor/react';
import clsx from 'clsx';
import React, { useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';
import { RiEyeFill, RiEyeOffFill, RiSearchLine } from '@remixicon/react';

export function cx(...args) {
  return twMerge(clsx(...args));
}

export const focusInput = [
  // base
  'focus:ring-2',
  // ring color
  'focus:ring-blue-200 focus:dark:ring-blue-700/30',
  // border color
  'focus:border-blue-400 focus:dark:border-blue-700',
];

export const hasErrorInput = [
  // base
  'ring-2',
  // border color
  'border-red-500 dark:border-red-700',
  // ring color
  'ring-red-200 dark:ring-red-700/30',
];

const inputStyles = tv({
  base: [
    // base
    'relative cursor-pointer block w-full appearance-none rounded-tremor-default border px-2.5 py-1.5 shadow-tremor-input dark:shadow-dark-tremor-input outline-none !text-tremor-default',
    // border color
    'border-tremor-border dark:border-dark-tremor-border',
    // text color
    'text-tremor-content-strong dark:text-dark-tremor-content-strong',
    // placeholder color
    'placeholder-tremor-content-subtle dark:placeholder-dark-tremor-content-subtle',
    // background color
    'bg-tremor-background dark:bg-dark-tremor-background',
    // disabled
    'disabled:bg-tremor-background-subtle disabled:text-tremor-content-subtle',
    'disabled:dark:bg-dark-tremor-background-subtle disabled:dark:text-dark-tremor-content-subtle',
    // file
    [
      'file:text-tremor-default file:-my-1.5 file:-ml-2.5 file:h-[36px] file:cursor-pointer file:rounded-l-tremor-small file:border-0 file:px-3 file:py-1.5 file:outline-none file:rounded-r-none',
      'file:border-solid file:border-tremor-border file:bg-tremor-background-muted file:text-tremor-content file:dark:bg-dark-tremor-background-muted file:dark:border-dark-tremor-border hover:file:bg-tremor-background-subtle/80 file:dark:bg-dark-tremor-background-muted hover:file:dark:bg-dark-tremor-background-subtle/30',
      'file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem]',
      'disabled:pointer-events-none file:disabled:pointer-events-none file:disabled:bg-tremor-background-subtle file:disabled:text-tremor-content file:disabled:dark:bg-dark-tremor-background-subtle file:disabled:dark:border-gray-700 focus:outline-none',
    ],
    // focus
    focusInput,
    // invalid (optional)
    // "aria-[invalid=true]:dark:ring-red-400/20 aria-[invalid=true]:ring-2 aria-[invalid=true]:ring-red-200 aria-[invalid=true]:border-red-500 invalid:ring-2 invalid:ring-red-200 invalid:border-red-500"
    // remove search cancel button (optional)
    '[&::--webkit-search-cancel-button]:hidden [&::-webkit-search-cancel-button]:hidden [&::-webkit-search-decoration]:hidden',
  ],
  variants: {
    hasError: {
      true: hasErrorInput,
    },
    // number input
    enableStepper: {
      true: '[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none',
    },
  },
});

const Input = React.forwardRef(
  ({ className, hasError, enableStepper, type, onChange, ...props }, forwardedRef) => {
    return (
      <div className="relative">
        <input
        onChange={onChange}
          ref={forwardedRef}
          type={type}
          className={cx(
            inputStyles({ hasError, enableStepper }),
            className,
          )}
          {...props}
        />
      </div>
    );
  },
);

Input.displayName = 'Input';

export default function FileUpload({description, accept, onSelectFile}) {

   const fileInputRef = useRef(null);

   const handleFileChange = (event) => {
   
    const file = event.target.files[0];
    if (file) {
        onSelectFile(file);
    }
  };


  return (
    <>
      <div className="sm:mx-auto sm:max-w-lg">
          <div className="space-y-6">
            <div>
              <label
                htmlFor="file-1"
                className="text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong"
              >
                Carica File
              </label>
              <Input

                ref={fileInputRef}
                id="file-1"
                name="file-1"
                type="file"
                className="mt-2"
                accept={accept}
                onChange={handleFileChange}
              />
              <p className="mt-2 text-tremor-label text-tremor-content dark:text-dark-tremor-content">
              {description}
              </p>
            </div>
          </div>
      </div>
    </>
  );
}