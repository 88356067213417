import { Caches } from "../caches/caches";
import SediGridComp from "./sediGridComp";

export default function Dashboard(){

    const arrayWithIds = Object.entries(Caches.baseInfoData).map(([key, value]) => ({
        id: key,
        ...value,
      }));
    
    return(
        <>
        <SediGridComp data={arrayWithIds}/>
        </>
    )
}