import { useEffect, useState } from "react";
import { RGrid, RRow, RText, SpaceHorizontal, Title2 } from "../comps/otherCOmps";
import { useTheme } from "../theme";
import { db, getInventario, getTokenUser } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import InvTable from "./inventarioTable";
import { showErrorNotification } from "../comps/errorMessagesComp";
import { ToastContainer } from "react-toastify";
import { Caches } from "../caches/caches";
import { Button, Flex, MultiSelect, MultiSelectItem } from "@tremor/react";
import { BaseData } from "../util/const";
import { RiAddLine, RiFileDownloadLine, RiFileUploadLine } from "@remixicon/react";
import LoadCSVDialog from "./loadcsvDialog";
import { getIdToken } from "firebase/auth";
import axios from "axios";

export default function InventarioPage(){


    const [isLoading, setIsLoading] = useState(true);

    const [invData, setInvData] = useState([]);

    const {sedeSelected} = useTheme();

    const [filtri, setFiltri] = useState({tip: [], rep: []});

    const [csvDialogOpen ,setCSVDialogOpen] = useState(false);

    const [isLoadingPdf, setIsLoadingPdf] = useState(false);

    useEffect(() => {
        getInv(false);          
    },[sedeSelected]);

    async function getInv(force = false){
        setIsLoading(true);
        const inv = await getInventario(sedeSelected, force);
        setInvData(inv);
        setIsLoading(false);
    }

    function invDataFiltered(invd){
        let invFilter = invd;
        if(filtri.tip.length > 0)
            invFilter = invFilter.filter(d => filtri.tip.includes(d.classe) );
        if(filtri.rep.length > 0)
            invFilter = invFilter.filter(d => filtri.rep.includes(d.reparto) );
        return invFilter;
    } 
    function ricaricaInventario(){
        getInv();
    }
    async function getInvPdf(){
        setIsLoadingPdf(true);
        try{
            const idToken = await getTokenUser();
            const apiUrl = 'https://us-central1-firechecklapi.cloudfunctions.net/creaPdfInventario';

            const requestData = {
                sedeEndpoint: sedeSelected.endpoint,
            };
            const config = {
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`
                },
                responseType: 'blob'
            };
            const response = await axios.post(apiUrl, requestData, config);

            const blob = new Blob([response.data], { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(blob);

            window.open(pdfUrl);
        }catch(e){
            console.log(e);
            showErrorNotification("C'è stato un errore nel generare il pdf");
        }
        setIsLoadingPdf(false);
    }
    return(
        <>
            <ToastContainer autoClose={2000} hideProgressBar={false} />
            {isLoading && <RText>Loading</RText>}
            {!isLoading && 
            <>
            <LoadCSVDialog isOpen={csvDialogOpen} setIsOpen={setCSVDialogOpen} ricaricaInv={ricaricaInventario}/>
             <RGrid horizontalAlign="space-between">
                <RGrid>
                <div style={{width: "300px"}}>
                    <MultiSelect placeholderSearch="Cerca"  onValueChange={(e) => setFiltri(p => ({...p, tip: e}))} placeholder="Tipolgie">
                        {Caches.getTipolgieSede(sedeSelected).map((tip, ind) => (
                            <MultiSelectItem key={ind} value={tip}>{tip}</MultiSelectItem>
                        ))}
                    </MultiSelect>
                </div>
                <SpaceHorizontal/>
                <div style={{width: "300px"}}>
                    <MultiSelect placeholderSearch="Cerca" onValueChange={(e) => setFiltri(p => ({...p, rep: e}))} placeholder="Reparti">
                        {Object.values(Caches.reparti[sedeSelected.endpoint] ?? {}).map((rep, ind) => (
                            <MultiSelectItem key={ind} value={rep.value}>{rep.value}</MultiSelectItem>
                        ))}
                    </MultiSelect>
                </div>
                </RGrid>
                <RRow>
                <Button variant="secondary" icon={RiFileDownloadLine} onClick={getInvPdf} disabled={isLoadingPdf} loading={isLoadingPdf}>Scarica PDF</Button>
                <SpaceHorizontal/>
                <Button variant="secondary" onClick={() => setCSVDialogOpen(true)} icon={RiFileUploadLine}>Carica CSV</Button>

                </RRow>
            </RGrid>
             <InvTable data={invDataFiltered(invData)}/>
            </>}
           
        </>
    )
}