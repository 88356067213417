import { Button, Callout, Dialog, DialogPanel, Flex, MultiSelect, MultiSelectItem, TextInput } from '@tremor/react';
import { RColumn, RRow, Space } from '../comps/otherCOmps';
import { useEffect, useState } from 'react';
import { useTheme } from '../theme';
import { Input } from '@headlessui/react';
import { Tit2Tremor } from '../comps/coolComp';
import { Caches } from '../caches/caches';
import { functions, getReparti } from '../firebase';
import { showErrorNotification } from '../comps/errorMessagesComp';
import { httpsCallable } from 'firebase/functions';


export default function AddUserDialog({isOpen, setIsOpen, ricaricaInv: ricaricaUsers}){

    const {sedeSelected} = useTheme();

    const [state, setState] = useState("start");
    const [stateMsg, setStateMsg] = useState("");
    
    const [reparti, setReparti] = useState({});

    const [userData, setUserData] = useState({
        email: "",
        password: "",
        nominativo: "",
        invTipNewUsr: [],
        repPerNewUsr: [],
    });

    useEffect(() => {
        if(isOpen === false){
            restart()
            if(state === "loaded"){
                ricaricaUsers();
            }
        }else{
            getReparti(sedeSelected).then(v => setReparti(v));
        }
    },[isOpen]);

  
    function restart(){
        setState("start");
        setStateMsg("");
        setUserData({
            email: "",
            password: "",
            nominativo: "",
            invTipNewUsr: [],
            repPerNewUsr: [],
        })
    }
    function caricaDisabled(){
        return !(userData.nominativo.length > 0 && userData.email.length > 0 && userData.invTipNewUsr.length > 0 && userData.password.length > 0 && userData.repPerNewUsr.length > 0);
    }
    async function caricaUser(){
        if(userData.nominativo.length < 3){
            showErrorNotification("Inserisci un nominativo valido");
            return;
        }
        if(userData.email.length < 3){
            showErrorNotification("Inserisci una email valida");
            return;
        }
        if(userData.password.length < 8){
            showErrorNotification("Inserisci una password di almeno 8 caratteri");
            return;
        }
        if(userData.invTipNewUsr.length < 1){
            showErrorNotification("Inserisci almeno una tipologia");
            return;
        }
        if(userData.repPerNewUsr.length < 1){
            showErrorNotification("Inserisci almeno un reparto");
            return;
        }
        const invTipNewUsr = {},repPerNewUsr = {};
        userData.invTipNewUsr.forEach(v => {
            invTipNewUsr[v] = true;
        });
        userData.repPerNewUsr.forEach(rep => {
            const k = Object.entries(reparti).find(([k,v]) => v.value === rep)[0];
            repPerNewUsr[k] = true;
        });
        setState("loading");
        try{
            const dataToPass = {
                email: userData.email,
                password: userData.password,
                aziendaUrl: sedeSelected.endpoint,
                user:{
                    nominativo: userData.nominativo,
                    admin: false,
                    nominativo: userData.nominativo,
                    mail: userData.email,
                },
                invTipNewUsr: invTipNewUsr,
                repPerNewUsr: repPerNewUsr,
            }
            const funct = httpsCallable(functions, "creaUtenteSecondario");
           const res = await funct(dataToPass);
            if(res.data.success !== true){
                setState("error");
                setStateMsg(res.data.msg);
                return;
            }
        }catch(e){
            setState("error");
            setStateMsg("C'è stato un errore");
            return;
        }
        setState("loaded");
        setUserData({
            email: "",
            password: "",
            nominativo: "",
            invTipNewUsr: [],
            repPerNewUsr: [],
        })
    }

     return(
        <Dialog open={isOpen} onClose={(val) => setIsOpen(val)}>
            <DialogPanel className="max-w-2xl h-[70vh] max-h-[600px] min-h-96 flex flex-col justify-between">
            <div className='flex-col justify-start h-min space-y-2'>
            <h3 className="text-lg font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                Aggiungi utente
            </h3>
            {state === "error" &&<>
                <Callout title="C'è stato un errore" color="red">
                {stateMsg}
                </Callout>
            </>}
            {state === "loaded" &&<>
                <Callout title="Utente Aggiunto!" color="green">
                L'utente è stato aggiunto
                </Callout>
            </>}
            <TextInput value={userData.nominativo} disabled={state === "loading"} placeholder='Nominativo' onChange={(e) => setUserData(p => ({...p, nominativo: e.target.value}))}/>
            <TextInput value={userData.email} disabled={state === "loading"}  placeholder='Email'  onChange={(e) => setUserData(p => ({...p, email: e.target.value}))}/>
            <TextInput value={userData.password} disabled={state === "loading"}  placeholder='Password'  onChange={(e) => setUserData(p => ({...p, password: e.target.value}))}/>
            <Space height='12px'/>
            <MultiSelect value={userData.invTipNewUsr} disabled={state === "loading"}  placeholderSearch="Cerca" placeholder="Tipolgie abilitate" onValueChange={(e) => setUserData(p => ({...p, invTipNewUsr: e}))}>
                {Caches.getTipolgieSede(sedeSelected).map((tip, ind) => (
                    <MultiSelectItem key={ind} value={tip}>{tip}</MultiSelectItem>
                ))}
            </MultiSelect>
            <MultiSelect value={userData.repPerNewUsr} disabled={state === "loading"}  placeholderSearch="Cerca" placeholder="Reparti abilitati" onValueChange={(e) => setUserData(p => ({...p, repPerNewUsr: e}))}>
                {Object.values(reparti).map((rep) => (
                    <MultiSelectItem key={rep.value} value={rep.value}>{rep.value}</MultiSelectItem>
                ))}
            </MultiSelect>
           {/*  <Space height='100%'/> */}
           </div>
            <Flex className='space-x-2'>
                <Button className='flex-1' type='primary' onClick={caricaUser} loading={state === "loading"}>Aggiungi</Button>
                <Button variant='secondary' onClick={() => setIsOpen(false)} disabled={state === "loading"}>Chiudi</Button>
            </Flex>
        
        </DialogPanel>
      </Dialog>
    )
}