import { useEffect, useState } from "react";
import { Caches } from "../caches/caches";
import { DropDownMenu, RColumn, RGrid, RRow, RText, Space, SpaceHorizontal } from "../comps/otherCOmps";
import { useTheme } from "../theme";
import { collection, doc, getDocs } from "firebase/firestore";
import { db, getReparti, getVerificheOggAnno } from "../firebase";
import VericheTable from "./verificheTable";
import { BaseData, ColorPalette, months } from "../util/const";
import { SelectTremor } from "../comps/coolComp";
import { Button, Dialog, DialogPanel,List, ListItem, Badge, Callout,Switch,Tab, TabGroup, TabList, TabPanel, TabPanels, Flex, MultiSelect, MultiSelectItem  } from '@tremor/react';
import { format, getMonth, getYear, parse, parseISO } from 'date-fns';
import { da, it } from 'date-fns/locale';
import { RiCheckFill, RiErrorWarningFill, RiAlarmWarningLine, RiCloseFill, RiCloseLine, RiFileForbidFill } from '@remixicon/react';
import colors from 'tailwindcss/colors';

export default function VerifichePage(){
    

    const {sedeSelected} = useTheme();

    const [showProblemi, setShowProblemi] = useState(false);

    const dateString = Caches.baseInfoData[sedeSelected.endpoint].plan.dataStart;

    const endYear = parseInt(dateString.split('-')[0], 10);
    const currentYear = new Date().getFullYear();
    
    const anniOptions = [];
    for (let year = currentYear; year >= endYear; year--) {
      anniOptions.push( {label: year.toString(), value: year.toString()});
    }
    
    const [selectedYear, setSelectedYear] = useState(anniOptions[0]);


    const [dataToShow, setDataToShow] = useState([]);

    const [isMonthViewOpen, setIsMonthViewOpen] = useState(false);
    const [monthDialogData, setMonthDialogData] = useState();
    const [monthDialogSelected, setMonthDialogSelected] = useState();

    const [filtri, setFiltri] = useState({tip: [], rep: []});


    useEffect(() => {
        getVers();
    },[selectedYear, showProblemi, sedeSelected]);

    const [currentDataVers, setCurrentDataVers] = useState([]);
    async function getVers(){
       
        const vers = await getVerificheOggAnno(sedeSelected, selectedYear);
        setCurrentDataVers(vers);
        createData(vers);
    }

    function openDialogMoreInfoMese(dat, mes, assente){
        setIsMonthViewOpen(true);
        setMonthDialogData( {ver: dat, assente: assente});
        setMonthDialogSelected(mes);
    }
    function createData(dat){
        let newD = [];
        Object.entries(dat).forEach(([key, value]) => {

        let mes = {};
        for (let i = 1; i <= 12; i++) {
            if(!value.mesi?.[i]){
                mes[`${i}`] = <div></div>
            }else{
                mes[`${i}`] = <div style={{cursor: "pointer"}} onClick={() => openDialogMoreInfoMese(value.mesi?.[i].verifiche, i, !value.mesi?.[i].presente)}><SingleCell dat={value?.mesi?.[i].verifiche} id={key} mese={i} presente={value.mesi?.[i].presente} /></div>;
            }

        }
        newD.push(
            {
                id: <RRow><img src={"logoTipologie/" + value.classe + ".png"} width={"18px"} style={{opacity: 0.7}}/><SpaceHorizontal width="6px"/>{value.id}</RRow>,
                reparto: value.reparto ?? "No rep",
                posizione:value.posizione ?? "No pos",
                ...mes,
            }
        )
          });
        setDataToShow(newD);
    }

    function semColor(sem){
        if(sem === "si") return colors.green[200];
        if(sem === "no") return colors.red[200];
        return null;
    }
    function SingleCell({dat, id, mese, presente}){
        if(presente === false) return<RRow horizontalAlign="center"><Pallino assente={true}/></RRow> 
        return(
            <div style={{backgroundColor: semColor(dat[0].sem), padding: "6px 2px", borderRadius: "6px"}}>
            <RRow horizontalAlign="center">
            {dat.map(v => (
                <Pallino key={v.id_week} ok={ showProblemi ? v.check_passati : v.verificato} num={dat.length}/>
            ))}
            </RRow>
            </div>
        )
    }

    function Pallino({ok, assente = false, num = 1}){
        const w = num === 1 ? "8px" : "5px";
        const br = num === 1 ? "6px" : "3px";
        return(
            <div style={{margin: "1px",width: w , height: "18px", borderRadius: br, backgroundColor: assente ? "#6b7280" : (ok ? ColorPalette.okGreenColor : ColorPalette.errorRedColor)}}></div>
        )
    }

    useEffect(() => {
        let invFilter = currentDataVers;
        if(filtri.tip.length > 0)
            invFilter = invFilter.filter(d => filtri.tip.includes(d.classe) );
        if(filtri.rep.length > 0)
            invFilter = invFilter.filter(d => filtri.rep.includes(d.reparto) );
        createData(invFilter);
    },[filtri]);
   

    return(
        <>
        <RColumn horizontalAlign="start" width={"100%"}>
            <RRow verticalAlignment="center">
                <SelectTremor options={anniOptions} width="150px" value={selectedYear} onSelect={(s) => {console.log(s); setSelectedYear(s)}}/>
                <SpaceHorizontal width="30px"/>
                <label htmlFor="switch" className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                    Mostra problemi
                </label>
                <SpaceHorizontal/>
                <Switch onChange={setShowProblemi}/>
            </RRow>
            <Space/>
            <Flex justifyContent="start">
                <div style={{width: "300px"}}>
                    <MultiSelect placeholderSearch="Cerca"  onValueChange={(e) => setFiltri(p => ({...p, tip: e}))} placeholder="Tipolgie">
                        {Caches.getTipolgieSede(sedeSelected).map((tip) => (
                            <MultiSelectItem key={tip} value={tip}>{tip}</MultiSelectItem>
                        ))}
                    </MultiSelect>
                </div>
                <SpaceHorizontal/>
                <div style={{width: "300px"}}>
                    <MultiSelect placeholderSearch="Cerca" onValueChange={(e) => setFiltri(p => ({...p, rep: e}))} placeholder="Reparti">
                        {Object.values(Caches.reparti?.[sedeSelected.endpoint] ?? {}).map((rep, ind) => (
                            <MultiSelectItem key={ind} value={rep.value}>{rep.value}</MultiSelectItem>
                        ))}
                    </MultiSelect>
                </div>
            </Flex>
            <Space/>
            <VericheTable data={dataToShow}/>
        </RColumn>
        <Dialog open={isMonthViewOpen} onClose={(val) => setIsMonthViewOpen(val)} static={true}>
            <DialogPanel>
                <h3 className="text-lg font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">Dettaglio {months[monthDialogSelected - 1]}</h3>                
                <div className="mx-auto max-w-md">
                   {!showProblemi ? <>
                   <List>
                    {monthDialogData?.ver?.[0].sem !== "na" && <ListItem>
                        <span>Controllo semestrale</span>
                        <Badge color={monthDialogData?.ver?.[0].sem === "si" ? "green" : "red"}>{monthDialogData?.ver?.[0].sem === "si" ? "Effettuato" : "Mancato"}</Badge>
                    </ListItem>}
                   {(monthDialogData?.ver ?? []).filter(v => v?.ver?.data).map((v) => (
                    <ListItem key={v.ver.data}>
                        <span>{format(parseISO(v?.ver?.data), 'd MMMM', { locale: it })}</span>
                        <Badge color={v.verificato ? "green" : "red"}>{v.verificato ? "Verificato" : "Mancato"}</Badge>
                    </ListItem>
                   ))}
                   
                   </List>
                   
                   </>: 
                   <TabGroup defaultIndex={0}>
                    <TabList variant="line">
                        {(monthDialogData?.ver ?? []).filter(v => (v?.ver?.data ?? "") !== "").map((v) => (
                            <Tab value={v}>{format(parseISO(v?.ver?.data), 'd MMMM', { locale: it })}</Tab>
                        ))}                  
                    </TabList>
                    {console.log(monthDialogData?.ver?.[0])}
                    <TabPanels>
                        {(monthDialogData?.ver ?? []).filter(v => v?.ver?.data).map((v) => (
                            <TabPanel>
                            <List>
                                {Object.values(v.ver?.verifiche ?? {}).map(singV => (
                                    <ListItem>
                                    <span>{singV.domanda}</span>
                                    <span>
                                    <Badge style={{paddingRight: "0px"}} color={singV.value ? "green" : "red"} icon={singV.value ? RiCheckFill :RiCloseFill}></Badge>
                                    </span>
                                    </ListItem>
                                ))}
                            </List>
                            </TabPanel>
                        ))}
                        </TabPanels>
                    </TabGroup>
                    }
                    {(monthDialogData?.ver ?? []).filter(v => (v?.ver?.data ?? "") !== "").length === 0 && 
                         <Callout
                         
                         className="mt-4"
                         title={monthDialogData?.assente ? "Dispositivo non presente" : "Nessuna verifica"}
                         icon={RiAlarmWarningLine}
                         color={monthDialogData?.assente ? "gray" : "rose"}
                       >
                         {monthDialogData?.assente ? "Non era presente il dispositivo nell'inventario" : "Non è stata fatta nessuna verifica durante questo mese"}
                       </Callout>
                       }
                </div>       
                <Button className="mt-8 w-full" onClick={() => setIsMonthViewOpen(false)}>
                Chiudi
                </Button>
            </DialogPanel>
        </Dialog>
        </>
    )
}