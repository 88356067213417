import { initializeApp } from "firebase/app";
import {
  getAuth,
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import {
  doc,
  getFirestore,
  getDoc,
  setDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { Caches } from "./caches/caches";
import { useTheme } from "./theme";
import { BaseData } from "./util/const";
const config = {
  apiKey: "AIzaSyD4m70RI343miZ5_xpefk67qMz4M_abY_E",
  authDomain: "firechecklapi.firebaseapp.com",
  databaseURL:
    "https://firechecklapi-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "firechecklapi",
  storageBucket: "firechecklapi.appspot.com",
  messagingSenderId: "859137941255",
  appId: "1:859137941255:web:9c2a0d99d4a9623be9afa2",
  measurementId: "G-4SKS9G2852",
};

const app = initializeApp(config);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const functions = getFunctions(app);

export const login = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const logOut = async () => {
  await signOut(auth);
};
export const getTokenUser = async () => {
  try {
    const user = auth.currentUser;
    if (user) {
      const idToken = await user.getIdToken(/* forceRefresh */ true);
      return idToken;
    } else {
      throw new Error("No user signed in");
    }
  } catch (error) {
    console.error("Error getting ID token:", error);
    throw error;
  }
};

export const baseSedeRefStr = (sedeSelected) => {
  return "aziende/" + sedeSelected.endpoint;
};
export const getInventario = async (sedeSelected, force = false) => {
  if (force === false && Caches.inventario?.[sedeSelected.endpoint])
    return Caches.inventario[sedeSelected.endpoint];

  const invRef = doc(
    db,
    "aziende",
    sedeSelected.endpoint,
    "info2",
    "inventario"
  );
  const invDoc = await getDoc(invRef);
  const reparti = await getReparti(sedeSelected);
  if (!invDoc.exists) {
    if (!Caches.inventario) Caches.inventario = [];
    Caches.inventario[sedeSelected.endpoint] = [];
    return [];
    //   throw "No inventario"
  }
  const invD = invDoc.data();
  if (!invD) {
    if (!Caches.inventario) Caches.inventario = [];
    Caches.inventario[sedeSelected.endpoint] = [];
    return [];
    // throw "No inventario";
  }

  const arrayWithIds = Object.entries(invD).map(([key, value]) => ({
    id: key,
    ...value,
    reparto: reparti[value["reparto"]].value,
  }));
  if (!Caches.inventario) Caches.inventario = [];
  Caches.inventario[sedeSelected.endpoint] = arrayWithIds;
  return arrayWithIds;
};

function getNumVerMesePerPeriod(period) {
  if (period >= 30) return 1;
  if (period >= 14) return 2;
  if (period >= 7) return 4;
  if (period === 1) return 30;
  return 1;
}

function analyzeVerificaMese(numVerifiche, verifiche, currentWeek) {
  let toReturn = {
    verificato: false,
    check_passati: false,
    sem: "na",
    ver: { data: "", verifiche: {} },
  };

  if (numVerifiche === 1) {
    Object.entries(verifiche).forEach(([key, ver]) => {
      if (!toReturn.verificato) toReturn.verificato = ver.verificato;
      if (!toReturn.check_passati) {
        toReturn.check_passati = ver.checkPassati === true;
        toReturn.ver = { verifiche: ver.verifiche, data: key };
      }

      if (toReturn.sem === "na") toReturn.sem = ver.controlloSemestrale;
    });
  } else if (numVerifiche === 2) {
    Object.entries(verifiche).forEach(([key, ver]) => {
      const dataVer = new Date(key);

      if (
        (dataVer.getDate() < 15 && currentWeek === 1) ||
        (dataVer.getDate() >= 15 && currentWeek === 2)
      ) {
        if (!toReturn.verificato) toReturn.verificato = ver.verificato;
        if (!toReturn.check_passati) {
          toReturn.check_passati = ver.checkPassati === true;
          toReturn.ver = { verifiche: ver.verifiche, data: key };
        }
        if (toReturn.sem === "na") toReturn.sem = ver.controlloSemestrale;
      }
    });
  } else if (numVerifiche === 4) {
    Object.entries(verifiche).forEach(([key, ver]) => {
      const dataVer = new Date(key);

      if (
        (dataVer.getDate() < 7 && currentWeek === 1) ||
        (dataVer.getDate() >= 7 &&
          dataVer.getDate() < 15 &&
          currentWeek === 2) ||
        (dataVer.getDate() >= 15 &&
          dataVer.getDate() < 23 &&
          currentWeek === 3) ||
        (dataVer.getDate() >= 23 && currentWeek === 4)
      ) {
        if (!toReturn.verificato) toReturn.verificato = ver.verificato;
        if (!toReturn.check_passati) {
          toReturn.check_passati = ver.checkPassati === true;
          toReturn.ver = { verifiche: ver.verifiche, data: key };
        }
        if (toReturn.sem === "na") toReturn.sem = ver.controlloSemestrale;
      }
    });
  }

  return toReturn;
}
export const getVerificheOggAnno = async (sedeSelected, selectedYear) => {
  if (Caches.allVerPerOgg[sedeSelected.endpoint]?.[selectedYear.value])
    return Caches.allVerPerOgg[sedeSelected.endpoint][selectedYear.value];

  const verRef = collection(
    db,
    "aziende",
    sedeSelected.endpoint,
    "allVerPerOgg",
    selectedYear.value.toString(),
    "oggetti"
  );
  const allOgg = await getDocs(verRef);

  let allVerPerOgg = {};

  allOgg.docs.forEach((d) => {
    allVerPerOgg[d.id] = d.data();
  });

  // inv
  const inv = await getInventario(sedeSelected);

  let datToReturn = [];

  inv.forEach((item) => {
    let mesi = {};
    const period = BaseData.periodPerClasse(item.classe);
    //metto i mesi
    const numMesi =
      selectedYear.value == new Date().getFullYear()
        ? new Date().getMonth() + 1
        : 12;
    for (let mese = 1; mese <= numMesi; mese++) {
      const numVerifiche = getNumVerMesePerPeriod(period);

      const verifiche = [];
      //verifiche al mese (settimanale / bisettimanale ecc)
      for (let ver = 1; ver <= numVerifiche; ver++) {
        verifiche.push({
          id_week: ver,
          ...analyzeVerificaMese(
            numVerifiche,
            allVerPerOgg?.[item.id]?.[mese.toString()] ?? {},
            ver,
            item.data,
            sedeSelected.value,
            mese
          ),
        });
      }
      mesi[mese.toString()] = {
        verifiche: verifiche,
        presente:
          new Date(item.data) <
          new Date(selectedYear.value + "-" + mese + "-01"),
      };
    }
    datToReturn.push({
      ...item,
      period: period,
      mesi: mesi,
    });
  });

  if (!Caches.allVerPerOgg[sedeSelected.endpoint])
    Caches.allVerPerOgg[sedeSelected.endpoint] = {};

  Caches.allVerPerOgg[sedeSelected.endpoint][selectedYear.value] = datToReturn;
  return datToReturn;
};

export const getReparti = async (sedeSelected) => {
  if (Caches.reparti?.[sedeSelected.endpoint])
    return Caches.reparti[sedeSelected.endpoint];

  const invRef = doc(db, "aziende", sedeSelected.endpoint, "info2", "reparti");
  const invDoc = await getDoc(invRef);
  if (!invDoc.exists) {
    throw "No reparti";
  }
  const invD = invDoc.data();
  if (!invD) {
    if (!Caches.reparti) Caches.reparti = {};
    Caches.reparti[sedeSelected.endpoint] = invD;
    return {};
    throw "No reparti";
  }

  if (!Caches.reparti) Caches.reparti = {};
  Caches.reparti[sedeSelected.endpoint] = invD;
  return invD;
};

export const getReportPercent = async (sedeSelected, month) => {
  const allVerOgg = await getVerificheOggAnno(sedeSelected, {
    label: new Date().getFullYear(),
    value: new Date().getFullYear(),
  });

  const totOgg = allVerOgg.length;
  let oggVer = 0,
    oggCheck = 0;

  let tipCountTot = {},
    tipCounter = {};
  allVerOgg.forEach((ogg) => {
    if (!tipCountTot[ogg.classe]) {
      tipCounter[ogg.classe] = 0;
      tipCountTot[ogg.classe] = 0;
    }
    tipCountTot[ogg.classe] += 1;
    if (ogg.mesi?.[month.toString()]?.verifiche[0].verificato) {
      oggVer += 1;
      tipCounter[ogg.classe] += 1;
    }
    if (ogg.mesi?.[month.toString()]?.verifiche[0].check_passati) oggCheck += 1;
  });
  return {
    verificati: oggVer,
    totOgg: totOgg,
    check_passati: oggCheck,
    classiVerific: tipCounter,
    totClassiCount: tipCountTot,
  };
};

export const getUtentiInfo = async (sedeSelected, force = false) => {
  if (force === false && Caches.utentiInfo?.[sedeSelected.endpoint])
    return Caches.utentiInfo[sedeSelected.endpoint];

  //ruoli
  const ruoliRef = collection(db, "aziende", sedeSelected.endpoint, "ruoli");
  const ruoliDocs = await getDocs(ruoliRef);

  //tipologie
  const tipologieRef = collection(
    db,
    "aziende",
    sedeSelected.endpoint,
    "verifichePerUtente"
  );
  const tipologieDocs = await getDocs(tipologieRef);
  let tipologieData = {};
  tipologieDocs.forEach((d) => (tipologieData[d.id] = d.data()));

  //reparti
  const repartiRef = collection(
    db,
    "aziende",
    sedeSelected.endpoint,
    "repartiPerUtente"
  );
  const repartiDocs = await getDocs(repartiRef);
  let repartiData = {};
  repartiDocs.forEach((d) => (repartiData[d.id] = d.data()));

  //repartiName
  const repartiNames = await getReparti(sedeSelected);

  if (!Caches.utentiInfo?.[sedeSelected.endpoint])
    Caches.utentiInfo[sedeSelected.endpoint] = [];

  ruoliDocs.forEach((d) => {
    const info = d.data();
    let tipologAttiv = [];
    let repartiAttiv = [];
    if (!tipologieData[d.id]) {
      //admin
      tipologAttiv = Object.keys(
        Caches.baseInfoData[sedeSelected.endpoint].inventarioTipologie ?? {}
      );
      repartiAttiv = Object.values(repartiNames);
    } else {
      tipologAttiv = Object.keys(tipologieData[d.id]);
      repartiAttiv = Object.keys(repartiData[d.id]).map((k) => repartiNames[k]);
    }
    Caches.utentiInfo[sedeSelected.endpoint].push({
      info: info,
      reparti: repartiAttiv,
      tipologie: tipologAttiv,
    });
  });
  return Caches.utentiInfo[sedeSelected.endpoint];
};
