import { Select, SelectItem } from "@tremor/react";

export function SelectTremor({ options, value, onSelect, width = "100%" }) {

    const handleChange = (newValue) => {
      const selectedOption = options.find(opt => opt.value === newValue) || { value: '', label: '' };
      onSelect(selectedOption);
    };
  
    return (
        <div style={{width: width}}>
            <Select value={value.value} onValueChange={handleChange} className="mt-2">
                {options.map((option) => (
                    <SelectItem key={option.value} value={option.value}>
                        {option.label}
                    </SelectItem>
                ))}
            </Select>
        </div>
    );
  }

  export function Tit2Tremor({children}){
    return(
        <h3 className="text-tremor-default font-bold text-tremor-content-strong dark:text-dark-tremor-content-strong">
            {children}
        </h3>
    )
}
export function DescTremor({children}){
    return(
        <p className="truncate text-tremor-default text-tremor-content dark:text-dark-tremor-content">
            {children}
        </p>
    )
}