import { BaseData } from "../util/const";

export class Caches{
    static baseInfoData = {};
    static allVerPerOgg = {};

    static inventario = null;

    static reparti = null;
    
    static utentiInfo = {};

    static getTipolgieSede(sedeSelected){
        return Object.keys(Caches.baseInfoData?.[sedeSelected.endpoint].inventarioTipologie ?? {});
    }
    static getMaxUsersSede(sedeSelected){
        return Caches.baseInfoData[sedeSelected.endpoint].plan?.maxUsers ?? BaseData.maxUsersDefault
    }
}