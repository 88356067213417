import { Badge, Button, Card, Divider, List, ListItem } from "@tremor/react";
import { RColumn, RRow, Space, SpaceHorizontal } from "../comps/otherCOmps";
import { RiArrowRightUpLine, RiPencilLine, RiUserAddLine } from "@remixicon/react";
import { DescTremor, Tit2Tremor } from "../comps/coolComp";
import { useEffect, useState } from "react";
import { useTheme } from "../theme";
import { getUtentiInfo } from "../firebase";
import SingleUtente from "./utente";
import AddUserDialog from "./addUserDialog";
import { Caches } from "../caches/caches";
import { BaseData } from "../util/const";
import { showErrorNotification } from "../comps/errorMessagesComp";

export default function UtentiPage(){

    const [utentiData, setUtentiData] = useState([]);
    const {sedeSelected} = useTheme();

    const [isAddUserDialogOpen, setIsAddUserOpen] = useState(false);

    const [isInMod, setisInMod] = useState(false);

    useEffect(() => {
        getDats();
    }, [sedeSelected]);
       
    async function getDats(force = false){
        const d = await getUtentiInfo(sedeSelected, force);
        setUtentiData(d);
    }

    function goAddUsers(){
        if(utentiData.length >= Caches.getMaxUsersSede(sedeSelected)){
            showErrorNotification("Massimo utenti raggiunto");
            return;
        }
        setIsAddUserOpen(true);
    }
    function reloadUsers(){
        getDats(true);
    }

    return(
        <>
        <AddUserDialog isOpen={isAddUserDialogOpen} ricaricaInv={reloadUsers} setIsOpen={setIsAddUserOpen}/>
        <RRow horizontalAlign="space-between">
            <RRow>
            <h3 className="text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                Utenti
                </h3>
                <SpaceHorizontal/>
                <span className="inline-flex h-6 w-6 items-center justify-center rounded-tremor-full bg-tremor-background-subtle text-tremor-label font-medium text-tremor-content-strong dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content-strong">
                {utentiData.length}
                </span>
            </RRow>
            <div className="space-x-2 items-center">
                <Button variant={isInMod ? "light" : "secondary"} icon={isInMod ? undefined : RiPencilLine} onClick={() => setisInMod(p => !p)}>{isInMod ? "Fine" : "Modifica"}</Button>
                <Button variant="secondary" icon={RiUserAddLine} onClick={goAddUsers}>Aggiungi</Button>
            </div>
        </RRow>
      <Divider className="my-4" />
      <div className="space-y-4">
      {utentiData.map(utente => (
            <SingleUtente key={utente.info.mail} utente={utente} isInMod={isInMod}/>
      ))}   
    </div>
        </>
    )
}