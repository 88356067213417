import './App.css';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import StartingPage from './startingPage';

function App() {
  return (
    <div className="App">
      <HashRouter>
        <StartingPage/>
    </HashRouter>
    </div>
  );
}

export default App;
