import { Button, Callout, Dialog, DialogPanel, Flex, Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow, } from '@tremor/react';
import "./inv.css"
import { RRow, Space } from '../comps/otherCOmps';
import FileUpload from '../comps/fileUpload';
import { useEffect, useState } from 'react';
import { loadingState } from '../util/const';
import Papa from 'papaparse';
import { baseSedeRefStr, db, getInventario, getReparti } from '../firebase';
import { useTheme } from '../theme';
import { DescTremor, Tit2Tremor } from '../comps/coolComp';
import { Caches } from '../caches/caches';
import { format } from 'date-fns';
import { doc, setDoc, updateDoc } from 'firebase/firestore';

export default function LoadCSVDialog({isOpen, setIsOpen, ricaricaInv}){

    function TabExamp(){
        return(
            <table className="all-border-black">
                <tbody>
                <tr className="all-border-black">
                    <td className="all-border-black">ID Disp 1</td>
                    <td className="all-border-black">Tipologia 1</td>
                    <td className="all-border-black">Reparto 1</td>
                    <td className="all-border-black">Ubicazione 1</td>
                </tr>
                <tr className="all-border-black">
                    <td className="all-border-black">ID Disp 2</td>
                    <td className="all-border-black">Tipologia 2</td>
                    <td className="all-border-black">Reparto 2</td>
                    <td className="all-border-black">Ubicazione 2</td>
                </tr>
                <tr className="all-border-black">
                    <td className="all-border-black">ID Disp 3</td>
                    <td className="all-border-black">Tipologia 3</td>
                    <td className="all-border-black">Reparto 3</td>
                    <td className="all-border-black">Ubicazione 3</td>
                </tr>
                </tbody>
              
                </table>
        )
    }
   

    const {sedeSelected} = useTheme();

    const [state, setState] = useState("start");
    const [stateMsg, setStateMsg] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [invAnalyzedData, setAnalyzedData] = useState(null);

    useEffect(() => {
        if(isOpen === false){
            restart()
            if(state === "uploaded"){
                ricaricaInv();
            }
        }
    },[isOpen]);

    function InvPreview(){
        return(
            <div className="flex flex-col h-full">
            <div className="overflow-auto">
            <Table >
              <TableHead>
                <TableRow>
                  <TableHeaderCell>ID</TableHeaderCell>
                  <TableHeaderCell>Tipologia</TableHeaderCell>
                  <TableHeaderCell>Reparto</TableHeaderCell>
                  <TableHeaderCell>Ubicazione</TableHeaderCell>
                </TableRow>
              </TableHead>
        
              <TableBody>
                {invAnalyzedData.map(ogg => (
                    <TableRow key={ogg[0]}>
                        {ogg.map((d, i) => (
                             <TableCell key={d}>
                                <RRow>
                                {i === 1 && <img src={`logoTipologie/${d}.png`} width={"22px"} style={{opacity: 0.6}}/>}
                                {d}
                                </RRow>
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          </div>
        )
    }

    function onSelectFile(file){
        console.log(file.name);
        setSelectedFile(file);
    }

    function restart(){
        setState("start");
        setSelectedFile(null);
        setAnalyzedData(null);
        setStateMsg("");
    }

   async function onClickAnalyze() {
        setState("loading");
        const inventarioOld = await getInventario(sedeSelected, true);
        const reparti = await getReparti(sedeSelected);
        
        const reader = new FileReader();
        
        const validIdPattern = /^[a-zA-Z0-9-_]+$/;

        reader.onload = (event) => {
            const csvData = event.target.result;
            Papa.parse(csvData, {
              header: false,
              complete: (results) => {
                for(let ogg of results.data){
                    //check id
                    if(inventarioOld.some(o => o.id === ogg[0])){
                        setState("error");
                        setStateMsg(`Dispositivo con ID ${ogg[0]} esiste già nell'inventario`);
                        return;
                    }
                    //check validità id
                    if(!validIdPattern.test(ogg[0])){
                        setState("error");
                        setStateMsg(`L'ID di un dispositivo può contenere solo lettere, numeri, "-" e "_". Problema per ${ogg[0]}`);
                        return;
                    }   
                    //check tipologia
                    if(!Caches.getTipolgieSede(sedeSelected).includes(ogg[1])){
                        setState("error");
                        setStateMsg(`La tipologia ${ogg[1]} non è presente in azienda. Puoi aggiungerla dalle impostazioni.`);
                        return;
                    }

                    //check reparto
                    if(!Object.values(reparti).some(r => r.value === ogg[2])){
                        setState("error");
                        setStateMsg(`Il reparto ${ogg[2]} non esiste. Puoi aggiungerne uno nuovo dalla sezione Reparti.`);
                        return;
                    }
                }
                setState("analyzed");
                setAnalyzedData(results.data);
              },
              error: (error) => {
                console.error('Error parsing CSV file:', error);
                setState("error");
               
              }
            });
          };
        reader.onerror = (error) => {
            console.error('Error reading CSV file:', error);
            setState("error");
        };
        reader.readAsText(selectedFile);
    }

    async function loadOnDB(){
        setState("loading");
        try{
        let newDataToUpload = {};
        const reparti = await getReparti(sedeSelected);
        console.log(reparti);
        for(let ogg of invAnalyzedData){
            const rep = Object.entries(reparti).find(([k,v]) => v.value === ogg[2])[0];
            newDataToUpload[ogg[0]] = {
                "classe": ogg[1],
                "data": format(new Date(), "yyyy-MM-dd"),
                "posizione": ogg[3] ?? "",
                "reparto": rep
            }
        }

        const invRef = doc(db, baseSedeRefStr(sedeSelected) + "/info2/inventario");
        await setDoc(invRef, newDataToUpload, {merge: true});
    }catch(e){
        console.log(e);
        setState("error");
        setStateMsg("C'è stato un errore nel caricare l'invetario.");
        return;
    }
        await getInventario(sedeSelected, true);
        setState("uploaded");
    }
    return(
        <Dialog open={isOpen} onClose={(val) => setIsOpen(val)}>
            <DialogPanel className="max-w-2xl h-[70vh] max-h-[600px] min-h-96 flex flex-col">
            <h3 className="text-lg font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">Carica l'inventario da un file CSV</h3>
            <Space/>
            {state === "loading" && <>
            Analizzo...
            </>}
            {state === "start" && <>
                <p className="mt-2 mb-1 leading-6 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                Il file CSV deve essere strutturato come nel seguente esempio
                </p>
                <TabExamp/>
                <Space/>
                <FileUpload description={"Accetta solo file .csv"} accept={".csv"} onSelectFile={onSelectFile}/>
                <Space height='100%'/>
                <Flex className='space-x-2'>
                <Button className='flex-1' type='primary' onClick={onClickAnalyze} disabled={selectedFile === null}>Analizza e carica</Button>
                <Button variant='secondary' onClick={() => setIsOpen(false)}>Chiudi</Button>
                </Flex>
            </>}
            {state === "analyzed" && <>
                <div style={{height: "78%"}}>
                <InvPreview/>
                </div>
                <Space height='100%'/>
                <div className='flex justify-center flex-row space-x-2' >
                <Button className='flex-1' variant='primary' onClick={loadOnDB}>Carica</Button>
                <Button variant='secondary' onClick={restart}>Usa altro file</Button>
                </div>
            </>}
            {state === "error" &&<>
                <div className='flex justify-between flex-col h-full'>
                <Callout title="C'è stato un errore" color="red">
                {stateMsg}
                </Callout>
                <Button type='primary' onClick={restart}>Carica un altro file</Button>
                </div>
            </>}
            {state === "uploaded" &&<>
                <div className='flex justify-between flex-col h-full'>
                <Callout title="Inventario aggiornato" color="green">
                I nuovi dispositivi sono stati caricati nell'inventario
                </Callout>
                <Button type='primary' onClick={() => setIsOpen(false)}>Fine</Button>
                </div>
            </>}
          
        </DialogPanel>
      </Dialog>
    )
}