import logo from "../assetts/firecheck_stondato.png";
import { Tit2Tremor } from "./coolComp";
import { RColumn, Space } from "./otherCOmps";

export const LoadingComp = () => {
    return(
        <RColumn>
            <img src={logo} width={"60px"}/>
            <Space/>
            <Tit2Tremor>Loading</Tit2Tremor>
        </RColumn>
    )
}