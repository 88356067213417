import {
  Card,
  List,
  ListItem,
  ProgressCircle,
  Select,
  SelectItem,
} from "@tremor/react";
import { Tit2Tremor } from "../comps/coolComp";
import { RColumn, RRow, Space, SpaceHorizontal } from "../comps/otherCOmps";
import { BarChart } from "@tremor/react";
import { Caches } from "../caches/caches";
import { useEffect, useState } from "react";
import { useTheme } from "../theme";
import { getInventario, getReportPercent } from "../firebase";
import { loadingState, months } from "../util/const";
import { LoadingComp } from "../comps/loading";

export default function DashboardSedePage() {
  let [chartdata, setchrtData] = useState([]);

  const dataFormatter = (number) => number + "%";

  const { sedeSelected } = useTheme();

  const [inv, setInv] = useState([]);
  const currentMonth = new Date().getMonth() + 1;

  const [selectedMoth, setSelectedMonth] = useState(currentMonth);

  const [state, setState] = useState(loadingState.LOADING);

  useEffect(() => {
    getD();
  }, [sedeSelected, selectedMoth]);

  const [allverPerc, setAllVerPerc] = useState();

  async function getD() {
    setState(loadingState.LOADING);
    try {
      const i = await getInventario(sedeSelected);
      setInv(i);
      const r = await getReportPercent(sedeSelected, selectedMoth);
      setAllVerPerc(r);
      setchrtData(
        Object.entries(r.classiVerific)
          .sort(([a], [b]) => a.localeCompare(b))
          .map(([tip, i]) => ({
            name: tip,
            "Dispositivi verificati": (i / r.totClassiCount[tip]) * 100,
          }))
      );
    } catch (e) {
      setState(loadingState.ERROR);
      return;
    }
    setState(loadingState.FINISH);
  }
  const tipologie = Caches.getTipolgieSede(sedeSelected);

  const splitArrayInHalf = (array) => {
    const midpoint = Math.ceil(array.length / 2);
    return [array.slice(0, midpoint), array.slice(midpoint)];
  };

  const [firstHalf, secondHalf] = splitArrayInHalf(tipologie);

  function colorPercent(p) {
    if (p >= 90) return "green";
    if (p >= 60) return "orange";
    return "red";
  }

  const dispVerPercentTxt = Math.floor(
    ((allverPerc?.verificati ?? 0) /
      (allverPerc?.totOgg > 0 ? allverPerc?.totOgg : 1)) *
      100
  );
  const dispCheckPassTxt = Math.floor(
    ((allverPerc?.check_passati ?? 0) /
      (allverPerc?.totOgg > 0 ? allverPerc?.totOgg : 1)) *
      100
  );

  function Data() {
    return (
      <>
        <RRow>
          <RColumn verticalAlignment="space-between" height={"100%"}>
            <Card className="mx-auto max-w-sm">
              <Tit2Tremor>Dispositivi verificati</Tit2Tremor>
              <Space />
              <div className="flex justify-start space-x-5 items-center">
                <ProgressCircle
                  value={dispVerPercentTxt}
                  size="md"
                  color={colorPercent(dispVerPercentTxt)}
                >
                  <span className="text-xs font-medium text-slate-700">
                    {dispVerPercentTxt}%
                  </span>
                </ProgressCircle>
                <div>
                  <p className="text-tremor-default text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">
                    {allverPerc?.verificati ?? "-"}/{allverPerc?.totOgg ?? "-"}
                  </p>
                  <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                    Dispositivi che sono stati scansionati per essere verificati
                  </p>
                </div>
              </div>
            </Card>
            <Space />
            <Card className="mx-auto max-w-sm">
              <Tit2Tremor>Dispositivi con esito positivo</Tit2Tremor>
              <Space />
              <div className="flex justify-start space-x-5 items-center">
                <ProgressCircle
                  value={dispCheckPassTxt}
                  size="md"
                  color={colorPercent(dispCheckPassTxt)}
                >
                  <span className="text-xs font-medium text-slate-700">
                    {dispCheckPassTxt}%
                  </span>
                </ProgressCircle>
                <div>
                  <p className="text-tremor-default text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">
                    {allverPerc?.check_passati ?? "-"}/
                    {allverPerc?.totOgg ?? "-"}
                  </p>
                  <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                    Dispositivi che hanno passato tutti i punti delle check-list
                  </p>
                </div>
              </div>
            </Card>
          </RColumn>
          <SpaceHorizontal width="30px" />
          <RColumn></RColumn>
          <Card>
            <BarChart
              data={chartdata}
              index="name"
              categories={["Dispositivi verificati"]}
              colors={["blue"]}
              valueFormatter={dataFormatter}
              yAxisWidth={48}
              onValueChange={(v) => console.log(v)}
            />
          </Card>
        </RRow>

        <Space />
        <Card className="mx-auto max-w-xxl">
          <h3 className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">
            Inventario
          </h3>
          <div className="mt-2 grid grid-cols-2 gap-8">
            <div>
              <List>
                {firstHalf.map((tip) => (
                  <ListItem key={tip}>
                    <RRow>
                      <img
                        src={"logoTipologie/" + tip + ".png"}
                        width={"18px"}
                        style={{ opacity: 0.7 }}
                      />
                      <SpaceHorizontal />
                      {tip}
                    </RRow>
                    <span>
                      {inv.filter((ogg) => ogg.classe === tip).length}
                    </span>
                  </ListItem>
                ))}
              </List>
            </div>
            <div>
              <List>
                {secondHalf.map((tip) => (
                  <ListItem key={tip}>
                    <RRow>
                      <img
                        src={"logoTipologie/" + tip + ".png"}
                        width={"18px"}
                        style={{ opacity: 0.7 }}
                      />
                      <SpaceHorizontal />
                      {tip}
                    </RRow>
                    <span>
                      {inv.filter((ogg) => ogg.classe === tip).length}
                    </span>
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
        </Card>
      </>
    );
  }
  return (
    <>
      <div style={{ width: "150px" }}>
        <Select defaultValue={currentMonth}>
          {months.slice(0, currentMonth).map((m, i) => (
            <SelectItem
              key={i}
              onClick={() => setSelectedMonth(i + 1)}
              value={i + 1}
            >
              {m}
            </SelectItem>
          ))}
        </Select>
      </div>
      <Space />
      {state === loadingState.LOADING && <LoadingComp />}
      {state === loadingState.FINISH && <Data />}
      <Space />
    </>
  );
}
