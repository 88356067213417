// 'use client';
import { RiArrowRightUpLine } from '@remixicon/react';
import { Card, Divider } from '@tremor/react';
import { useTheme } from '../theme';
import { useNavigate } from 'react-router-dom';
import FileUpload from '../comps/fileUpload';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}


export default function SediGridComp({data}) {

    const {setNewSedeWithEnpoint} = useTheme();

    const nav = useNavigate();

    function planToNam(p){
        if(p === 0) return "Base";
        if(p === 1) return "Standard";
        if(p === 2) return "Premium"
    }

    function changeSede(news){
      setNewSedeWithEnpoint(news);
      nav("/dashboard");
    }
  return (
    <>
      <div className="flex items-center space-x-2">
        <h3 className="text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
          Sedi
        </h3>
        <span className="inline-flex h-6 w-6 items-center justify-center rounded-tremor-full bg-tremor-background-subtle text-tremor-label font-medium text-tremor-content-strong dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content-strong">
          {data.length}
        </span>
      </div>
      <Divider className="my-4" />
      <div className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {data.map((sede) => (
          <Card key={sede.id} className="group p-4 cursor-pointer" onClick={() => changeSede(sede.id)}>
            <div className="flex items-center space-x-4">
              <span
                className={classNames(
                  "text-blue-800 dark:text-blue-500",
                  "bg-blue-100 dark:bg-blue-500/20",
                  'flex h-12 w-12 shrink-0 items-center justify-center rounded-tremor-full text-tremor-default font-medium',
                )}
                aria-hidden={true}
              >
                {sede.initial}
              </span>
              <div className="truncate">
                <p className="truncate text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                  <a className="focus:outline-none">
                    {/* Extend link to entire card */}
                    <span className="absolute inset-0" aria-hidden={true} />
                    {sede.info?.nome ?? ""}
                  </a>
                </p>
                <p className="truncate text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                  {sede.info?.indirizzo ?? ""}
                </p>
              </div>
            </div>
            <div className="mt-6 grid grid-cols-2 divide-x divide-tremor-border border-t border-tremor-border dark:divide-dark-tremor-border dark:border-dark-tremor-border">
              
                <div className="truncate px-3 py-2">
                  <p className="truncate text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                    Piano
                  </p>
                  <p className="truncate text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    {planToNam(sede.plan.plan)}
                  </p>
                </div>

                <div className="truncate px-3 py-2">
                  <p className="truncate text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                    Durata
                  </p>
                  <p className="truncate text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    {sede.plan.durata} gg
                  </p>
                </div>
            
            </div>
            <span
              className="pointer-events-none absolute right-4 top-4 text-tremor-content-subtle group-hover:text-tremor-content dark:text-dark-tremor-content-subtle group-hover:dark:text-dark-tremor-content"
              aria-hidden={true}
            >
              <RiArrowRightUpLine className="h-4 w-4" aria-hidden={true} />
            </span>
          </Card>
        ))}
      </div>
    </>
  );
}