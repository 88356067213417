import { useEffect, useState } from "react";
import { auth, db, logOut } from "./firebase";
import LoginPage from "./loginPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PagesContainer from "./pagesContainer";
import { doc, getDoc } from "firebase/firestore";
import { useTheme } from "./theme";
import { Caches } from "./caches/caches";
import { BaseData, loadingState } from "./util/const";
import { RColumn, Space } from "./comps/otherCOmps";
import { LoadingComp } from "./comps/loading";
import { Button, Callout, Card } from "@tremor/react";

export default function StartingPage() {
  const [state, setState] = useState("loading");
  const [stateMsg, setStateMsg] = useState("logged_out");

  const { setNewSede } = useTheme();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authUserm) => {
      if (authUserm) {
        handleLogged(authUserm.uid);
      } else {
        setState("logged_out");
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  async function handleLogged(uid) {
    try {
      setState("loading");
      const sediRef = doc(db, "B_userToDatabase", uid);
      const sediDoc = await getDoc(sediRef);
      const sediData = sediDoc.data() ?? {};
      const sediInfo = Object.values(sediData);

      // base data

      const tipoVerPerClasseDoc = await getDoc(
        doc(db, "A_generale", "tipoVerPerClasse")
      );
      const pianiDoc = await getDoc(doc(db, "A_generale", "piani"));

      BaseData.tipoVerPerClasse = tipoVerPerClasseDoc.data();
      BaseData.pianiInfo = pianiDoc.data();

      if (Object.keys(sediInfo).length <= 0) {
        setState("error");
        setStateMsg("Non ci sono aziende per questo account");
        return;
      }

      for (const sed of sediInfo) {
        const bref = doc(db, "aziende", sed.endpoint);
        const baseDat = await getDoc(bref);
        if (!baseDat.exists()) continue;
        if (baseDat.data().plan.plan < 1) continue;
        console.log(sed.nome);
        Caches.baseInfoData[sed.endpoint] = {
          endpoint: sed.endpoint,
          ...baseDat.data(),
        };
        if (!Caches.baseInfoData[sed.endpoint].info) {
          Caches.baseInfoData[sed.endpoint].info = {
            nome: sed.nome,
            indirizzo: sed.indirizzo,
            configurata: false,
          };
        }
      }

      if (Object.keys(Caches.baseInfoData).length <= 0) {
        setState("error");
        setStateMsg(
          "La sede collegata a questo account ha l'abbonamento che non supporta l'accesso via web."
        );
        return;
      }
      const firstSed = Object.values(Caches.baseInfoData)[0];
      setNewSede({
        endpoint: firstSed.endpoint,
        indirizzo: firstSed.info?.indirizzo,
        nome: firstSed.info?.nome,
      });

      setState("logged_in");
    } catch (error) {
      console.log(error);
      setState("error");
      setStateMsg("Errore generico");
    }
  }

  if (state === "loading") {
    return (
      <RColumn verticalAlignment="center" height={"90vh"}>
        <LoadingComp />
      </RColumn>
    );
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
      />
      {state === "logged_in" && <PagesContainer />}
      {state === "logged_out" && <LoginPage />}
      {state === "error" && (
        <>
          <div className="flex justify-center items-center h-screen">
            <Card className="max-w-xl">
              <Callout title="Errore">{stateMsg}</Callout>
              <Space />
              <div className="space-x-3">
                <Button onClick={logOut}>Esci</Button>
                <Button variant="secondary">
                  Contatta il servizio clienti
                </Button>
              </div>
            </Card>
          </div>
        </>
      )}
      {state === "error" && (
        <>
          <div className="flex justify-center items-center h-screen">
            <Card className="max-w-xl">
              <Callout title="Errore">{stateMsg}</Callout>
              <Space />
              <div className="space-x-3">
                <Button onClick={logOut}>Esci</Button>
                <Button variant="secondary">
                  Contatta il servizio clienti
                </Button>
              </div>
            </Card>
          </div>
        </>
      )}
    </>
  );
}
