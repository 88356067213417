import { RiLogoutBoxLine } from "@remixicon/react";
import { Button } from "@tremor/react";
import { Space } from "../comps/otherCOmps";
import { logOut } from "../firebase";

export default function UserSettingsTab(){
    return(
        <>
        <Space/>
        <Button variant="secondary" icon={RiLogoutBoxLine} onClick={() => logOut()}>Esci</Button>
        </>
    )
}