import { Badge, Button, Card, Divider, List, ListItem } from "@tremor/react";
import { RColumn, RRow, Space, SpaceHorizontal } from "../comps/otherCOmps";
import { DescTremor, Tit2Tremor } from "../comps/coolComp";
import { useState } from "react";

export default function SingleUtente({utente, isInMod}){

    const [isExpanded, setIsExpanded] = useState(false);

    const rowToShow = 3;
return(
    <Card key={utente.info.mail}>
    <RRow horizontalAlign="space-between">
        <RRow verticalAlignment="start">
            <RColumn horizontalAlign="start">
                <Tit2Tremor>{utente.info.nominativo}</Tit2Tremor>
                <DescTremor>{utente.info.mail}</DescTremor>
            </RColumn>
            <SpaceHorizontal/>
            <Badge color={"blue"}>{utente.info.admin === true ? "Admin" : "Base"}</Badge>
        </RRow>
       {!utente.info.admin && isInMod && <Button variant="light" color="red">Elimina</Button>}
    </RRow>
    <Divider/>
    <RRow verticalAlignment="start" horizontalAlign="space-between">
        <RColumn horizontalAlign="start" width={"48%"}>
            <DescTremor>Reparti abilitati</DescTremor>
            <List>
                {utente.reparti.slice(0, isExpanded ? utente.reparti.length : rowToShow).map(reparto => (
                    <ListItem key={reparto.value}>
                       <RRow><img onError={(e) => e.currentTarget.src = "logoTipologie/custom.png" } src={"logoTipologie/" + (reparto.icon ?? "custom") + ".png"} width={"18px"} style={{opacity: 0.7}}/><SpaceHorizontal/>{reparto.value}</RRow>
                    </ListItem>
                ))}             
                {!isExpanded && utente.reparti.length > rowToShow && <ListItem style={{cursor: "pointer"}} onClick={() => setIsExpanded(p => !p)}>Altri {utente.reparti.length - rowToShow}</ListItem>}
                 
            </List>
        </RColumn>
        <SpaceHorizontal/>
        <RColumn horizontalAlign="start" width={"48%"}>
            <DescTremor>Tipologie abilitate</DescTremor>
            <List>
                {utente.tipologie.slice(0, isExpanded ? utente.tipologie.length : rowToShow).map(tip => (
                    <ListItem key={tip}>
                        <RRow><img src={"logoTipologie/" + tip + ".png"} width={"18px"} style={{opacity: 0.7}}/><SpaceHorizontal/>{tip}</RRow>
                    </ListItem>
                ))}
                {!isExpanded && utente.tipologie.length > rowToShow && <ListItem style={{cursor: "pointer"}} onClick={() => setIsExpanded(p => !p)}>Altri {utente.tipologie.length - rowToShow}</ListItem>}
            </List>
        </RColumn>
    </RRow>
        
    </Card>
)
}