import {
    Card,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
  } from '@tremor/react';
import UserSettingsTab from './userSettings';
  

export default function SettingsPage(){
    return(
        <>
        <TabGroup>
            <TabList>
            <Tab>Utente</Tab>
            <Tab>Azienda</Tab>
            </TabList>
            <TabPanels>
            <TabPanel>
               <UserSettingsTab/>
            </TabPanel>
            <TabPanel>
                <p className="mt-4 leading-6 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                    Azienda
                </p>
            </TabPanel>
            </TabPanels>
        </TabGroup>
        </>
    )
}