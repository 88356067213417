import React, { createContext, useContext, useEffect, useState } from 'react';
import { Caches } from './caches/caches';

const MainContext = createContext();

export const MainThemeProvider = ({ children }) => {
  const [sedeSelected, setSedeSelected] = useState({endpoint: "", indirizzo: "", nome: ""});
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);


  const setNewSede = (newSede) => {
    setSedeSelected(newSede);
  };

  const setNewSedeWithEnpoint = (endpoint) => {
    console.log(endpoint);
    setSedeSelected(Object.values(Caches.baseInfoData).filter(s => s.endpoint === endpoint)[0])
  }


 const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <MainContext.Provider value={{ setNewSede, isMobile, sedeSelected,setNewSedeWithEnpoint }}>
      {children}
    </MainContext.Provider>
  );
};

export const useTheme = () => {
  return useContext(MainContext);
};
