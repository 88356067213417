import { RiCloseLine, RiExternalLinkLine } from '@remixicon/react';
import { Card } from '@tremor/react';
import { useState } from 'react';
import { Space } from './otherCOmps';

export default function BannerMessage({tit, desc, primaryBtnTxt, onPrimaryBtnClick}) {
  const [isOpen, setIsOpen] = useState(true);


  return isOpen ? (
    <>
    <Space/>
      <Card>
        <div className="absolute right-0 top-0 pr-3 pt-3">
          <button
            type="button"
            className="rounded-tremor-small p-2 text-tremor-content-subtle hover:bg-tremor-background-subtle hover:text-tremor-content dark:text-dark-tremor-content-subtle hover:dark:bg-dark-tremor-background-subtle hover:dark:text-tremor-content"
            onClick={() => setIsOpen(false)}
            aria-label="Close"
          >
            <RiCloseLine className="h-5 w-5 shrink-0" aria-hidden={true} />
          </button>
        </div>
        <h3 className="text-tremor-title font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
          {tit}
        </h3>
        <p className="mt-2 text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content">
          {desc}
        </p>
        <div className="mt-6 flex items-center space-x-5">
          <button
            onClick={onPrimaryBtnClick}
            type="button"
            className="whitespace-nowrap rounded-tremor-small bg-tremor-brand px-4 py-2 text-tremor-default font-medium text-tremor-brand-inverted shadow-tremor-input hover:bg-tremor-brand-emphasis dark:bg-dark-tremor-brand dark:text-dark-tremor-brand-inverted dark:shadow-dark-tremor-input dark:hover:bg-dark-tremor-brand-emphasis"
          >
            {primaryBtnTxt}
          </button>
          <button
            onClick={() => setIsOpen(false)}
            className="inline-flex items-center gap-1.5 text-tremor-default font-medium text-tremor-brand dark:text-dark-tremor-brand"
          >
            Chiudi
          </button>
        </div>
      </Card>
    </>
  ) : null;
}